.projectsInfoBarContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px 50px;
    padding: 25px;
}

.projectInfoBarElement {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    padding: 5px 7px;
    background-color: var(--lightRed);
}

.projectInfoBarTools {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.projectInfoBarToolsTitle {
    display: flex;
    padding: 10px;
    background-color: var(--lightRed);
    gap: 10px;
    border-radius: 5px;
}

.projectInfoBarToolsTitle h3{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-weight: 400;
    text-wrap: nowrap;
    white-space: nowrap;
    font-size: 1rem;
    color: var(--gray);
}

.projectInfoBarElementIcon {
color: var(--red);
}

.projectInfoBarElement h3{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-weight: 400;
    text-wrap: nowrap;
    font-size: 1rem;
    color: var(--gray);
}

.projectInfoBarList {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    gap: 10px;
    border-radius: 5px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
}


.projectInfoBarTools ul {
    background-color: transparent;
}

.toolImg {
    height: 25px;
    display: flex;
    max-width: 40px;
    align-items: center;
}

.projectInfoBarGraphicListElement {
    display: flex;  
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: white;
    padding: 5px 10px;
    background-image: linear-gradient(to bottom, #ff5656, #ff0000);
    border-radius: 5px;
    font-weight: 300;
}

.projectInfoBarCodingListElement {
    display: flex;  
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: white;
    padding: 5px 10px;
    background-image: linear-gradient(to bottom, #3c3c3c, #252525);
    border-radius: 5px;
    font-weight: 300;
}

.projectInfoBarListElement span {
    display: flex;
    min-width: 100%;
    align-items: center;
    color: var(--gray);
}

.ProjectBasicInfos {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

@media only screen and (max-width: 900px) {
    .projectsInfoBarContainer{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 25px 50px;
        padding: 25px 0px;
    }

    
  }