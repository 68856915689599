.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  height: 0;
  border-radius: 15px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
