/* ---------------------------------------> PORTFOLIO */

.portfolio {
    width: 100%;
    text-align: center;
    display: flex;
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
}

.portfolioTitle {
  display: flex;
  gap: 15px;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    var(--lightRed),
    var(--midLightRed)
  );
  padding: 5px 20px;
  font-size: 3.5rem;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--gray);
  border-radius: 15px;
}

.titleIcon {
    width: 50px;
}

.imagesContainer{
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filtersList {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 25px;
    list-style-type: none;
    flex-wrap: wrap;
}

.filter {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: var(--lighterGray);
    position: relative;
    font-size: 0.85em;
    color: var(--gray);
    transition: all 0.2s linear;
    cursor: pointer;
    font-weight: 300;
}

.filter:hover{
    transition: all 0.2s linear;
    color: white;
    background-color: var(--red);

}

.filter:focus{
    color: white;
    background-color: var(--red);
}

.filterSelected {
    position: absolute;
    width: 125%;
    height: 125%;
    top: -12%;
    left: -12%;
    right: 0;
    border-radius: 5px;
    background: var(--lightGray);
    z-index: -1;
    opacity: 0.35;
  }

.imagesBox {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.75rem;
    padding: 0px 50px;
}

.project {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-items: center;
    height:15vw;
    border-radius: 16px;
    /* border: 1px solid var(--lightRed); */
    /* box-shadow: 0px 5px 5px var(--lightGray); */
}

.project p{
    padding: 0px 25px;
    width: 100%;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 500;
    line-height: 100%;
    min-height: 100%;
    background-color: rgba(255, 0, 0, .75);
    border-radius: 15px;
    opacity: 0;
    transition: opacity 0.5s linear;
    vertical-align: middle;
    cursor: pointer;
}

.project p:hover{
    opacity: 1;
    transition: opacity 0.5s linear;
}

.project p:active{
    opacity: 1;
    transition: opacity 0.5s linear;
    color: white;
}

.project img{
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

/* Portfolio [[[[[[[[[[Responsive]]]]]]]]]] */

@media only screen and (max-width: 1200px) {

    .imagesBox {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0.75rem;
    }

    .project {
        height: 25vw;
    }

}

@media only screen and (max-width: 900px) {

    .titleIcon {
        width: 25px;
    }

    .imagesBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.75rem;
    }

    .project {
        height: 30vw;
    }

}

@media only screen and (max-width: 650px) {

    .portfolio {
        gap: 0px;
    }

    .imagesBox {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.75rem;
        padding: 0px 25px;
        }

        .filtersList {
            padding: 25px;
            gap: 15px;
        }

        .project {
            position: relative;
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-items: center;
            text-align: center;
            height: 50vw;
            border-radius: 15px;
        }
        
        .project img{
            object-fit: cover;
            min-width: 100%;
            max-height: 100%;
            border-radius: 15px;
        }

}