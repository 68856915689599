.backgroundPopup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #3c3c3cb4;
    z-index: 5001;
}

.popupContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5001;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    color: var(--gray);
    box-shadow: 0 0 50px var(--gray);
    background-image: linear-gradient(to bottom, white, var(--lightRed));
}

.popupContainerTopBar {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 30px;
    background-image: linear-gradient(to bottom, var(--red), var(--darkRed) );
    border-radius: 15px 15px 0 0;
}

.popupTitleContainer {
    display: flex;
    align-items: center;
    border-radius: 5px;  
    gap: 15px;
    padding: 10px 15px;
    background-color: white;
}

.popupTitle {
    font-size: 1.5rem;
    color: var(--gray);
}

.popupExit {
    justify-content: flex-end;
    font-family: 'Dosis', sans-serif;
    font-weight: 1000;
    z-index: 1001;
    padding: 5px 10px;
    background-color: var(--gray);
    border: none;
    border-radius: 5px;
    color: white;
    transition: 0.3s;
    cursor: pointer;
}

.popupExit:active {
    background-color: var(--red);
    border-color: var(--red);
}

.popupExit:hover {
    transform: scale(1.2);
    color: var(--white);
    box-shadow: 0 0 10px var(--lightGray);
}

.popupExit:focus {
    color: white;
}

.popupExit:visited {
    color: white;
}

.popupContainerBody {
    padding: 0px 35px 30px 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.popupListTextRocket {
    border-radius: 5px  ;
    padding: 10px 25px;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.popupRocket {
    display: flex;
    font-size: 3rem ;
    justify-content: flex-end;
}

.popupText {
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    color: var(--gray);
    border-radius: 5px;
    font-weight: 500;
}

.popupText:active {
    color: var(--gray);
}

.popupForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start ;
}

.poupTextAreaContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: (var(--gray));
}

.popupTextArea {
    font-family: "Dosis", sans-serif;
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color: var(--gray);
}

.popupTextArea:focus {
    color: var(--gray);
}

.popupTextAreaMessage {
    font-family: "Dosis", sans-serif;
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin-top: 15px;
    color: var(--gray);
}

.popupTextAreaMessage:focus {
    color: var(--gray);
}

.popupIcon {
    color: var(--gray);
}

.popupIcon:active {
    color: var(--gray);
}

.checkboxContainer {
    width: 100%;
    font-size: 0.75rem;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: var(--gray);
}

.checkboxContainer input {
    cursor: pointer;
}

.checkboxContainer label {
    color: var(--gray);
}

.checkboxContainer:active {
    color: var(--gray);
}

.popupSend {
    margin-top: 25px;
    font-family: 'Dosis', sans-serif;
    color: var(--red);
    padding: 10px 25px;
    border: 1px solid var(--red);
    border-radius: 5px;
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;
}

.popupSend:hover {
    transform: scale(1.1);
    background-color: var(--red);
    color: var(--white);
}

.popupContainerBodySend {
    display: flex;
    gap: 15px;
    padding: 0px 50px 25px 75px;
    align-items: center;
}

.bodySendImgContainer {
    width: 50%;
}

.bodySendImg {
    width: 100%;
}

.bodySendMsg {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.bodySendMsg h1{
    color: var(--gray);
}

.bodySendMsg p{
    color: var(--gray);
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: auto;
    box-shadow: 0px 5px 10px var(--lightGray);
}

@media only screen and (max-width: 768px) {

    .popupContainer {
        width: 75%;
        gap: 0px;
    }

    .popupContainerTopBar {
        padding: 20px;
    }

    .popupContainerBody {
        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .popupListTextRocket {
        border-radius: 5px ;
        padding: 15px;
        gap: 15px;
    }

    .popupTextAreaMessage {
        margin-top: 0px;
    }

    .popupRocket {
        display: flex;
        font-size: 2rem ;
        justify-content: flex-end;
    }

    .checkboxContainer {
        margin-top: 0px;
    }

    .popupSend {
        margin-top: 0px;
    }

    .popupContainerBodySend {
        display: flex;
        gap: 15px;
        padding: 25px;
        align-items: center;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }

    .bodySendMsg {
        align-items: center;
        justify-content: center;
    }

    .bodySendMsg p{
        margin-right: auto;
        margin-left: auto;
    }
}