/* FeaturesWithIcons */

.FeaturesWithIconsContainer {
  display: flex;
  padding: 25px 50px 100px 50px;
  gap: 25px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.servicesContainer {
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
}

.titleContainer {
  width: 100%;
  min-width: 250px;
  max-width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: baseline;
  background-color: white;
  /* background-image: linear-gradient(to bottom, white, var(--lightRed)); */
  border-radius: 10px;
  box-shadow: 0px 5px 15px var(--lightGray);
  color: var(--gray);
  flex-wrap: wrap;
}

.titleContainer:active p{
  color: var(--gray);
}

.FeatureTitletopBar { 
  display: flex;
  padding: 10px;
  background-image: linear-gradient(to bottom, var(--lightRed), var(--midLightRed));
  /* background-image: linear-gradient(to bottom, #3c3c3c, #292625); */
  width: 100%;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
}

.FeatureContent {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 15px;
}

.titleContainer:nth-of-type(4) {
  color: white;
  border-radius: 15px;
  background-image: linear-gradient(to bottom, #3c3c3c, #292625);
  border: 5px solid #00f7ff;
  /* box-shadow: 0px 5px 20px #00f7ffad; */
}

.titleContainer:nth-of-type(4):active p{
  color: white;
}

.titleContainer:nth-of-type(4):active .FeatureContent .subtitle p{
  color: var(--gray);
}

.titleContainer:nth-of-type(4) .introSubtitleIcon {
  font-size: 1.5rem;
}

.titleContainer:nth-of-type(4) .FeatureTitletopBar{
  background-image: linear-gradient(to bottom, var(--red), var(--darkRed) );
}

.titleContainer:nth-of-type(4) .FeatureTitletopBar .servicesTitle{
  color: white;
}

.servicesContainerNew {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.titleContainerNew {
  border-radius: 15px;
  padding: 20px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 35px 15px;
  background-color: var(--gray);
}

.titleContainerNew p {
  color: white;
}

.titleContainerNew p:active {
  color: white;
}

.servicesTitle {
  border-radius: 5px;
  font-weight: 700;
  text-wrap: nowrap;
  color: var(--gray);
  /* color: white; */
  font-size: 1rem;
}

.servicesTitle:active {
  color: var(--gray);
}

.introSubtitleIcon {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  padding: 5px;
  background-color: var(--lightRed);
  margin-right: auto;
  border-radius: 5px;
  color: var(--red);
}

.subtitleText {
  padding: 5px;
  border-radius: 5px;
  color: var(--gray);
  background-color: white;
}

.FeaturesWithIconsArrowRight {
  display: none;;
}

.FeaturesWithIconsArrowLeft {
  display: none;
}

/* FeaturesWithIcons [[[[[[[[[[Responsive]]]]]]]]]] */

@media only screen and (max-width: 1200px) {

  .FeaturesWithIconsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 50px 50px 50px;
    gap: 25px;
    align-items: flex-end;
    position: relative;
  }

  .servicesContainer {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 100px;
  }

  .titleContainer {
    max-width: 350px;
  }

}

@media only screen and (max-width: 1000px) {

  .FeaturesWithIconsContainer {
    padding: 0px 50px 50px 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    max-width: none;
    min-width: none;
  }

  .FeaturesWithIconsTitle {
    color: var(--gray);
    text-align: center;
    padding: 10px;
    z-index: 1;
    padding: 15px;
    background-color: var(--lightRed);
    border-radius: 10px;
    box-shadow: 0px 5px 10px var(--lightGray);
    font-weight: 500;
    display: flex;
    justify-content: center;
    font-size: 1rem;
  }

  .servicesContainer {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0px;
    width: 100%;
    height: 200px;
  }

  .titleContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .titleContainer:nth-of-type(4) {
    color: white;
    background-color: var(--gray);
    box-shadow: none;
  }

  .FeaturesWithIconsArrowRight {
    display: flex;
    position: absolute;
    top: 45%;
    right: 25px;
    z-index: 1;
    padding: 10px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: var(--red);
    box-shadow: 0px 5px 5px var(--lightGray);
  }

  .FeaturesWithIconsArrowLeft {
    rotate: 180deg;
    display: flex;
    position: absolute;
    top: 45%;
    left: 25px;
    z-index: 1;
    padding: 10px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: var(--red);
    box-shadow: 0px 5px 5px var(--lightGray);
  }

  .FeatureTitletopBar {
    justify-content: center;
  }
  
  .FeatureContent {
    display: flex;
    padding: 0px 20px;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  .subtitle {
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 400;
    padding: 5px;
    background-color: var(--lightRed);
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    color: var(--red);
  }
}


