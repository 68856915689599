.projectImagesContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.projectImages {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.projectVideos {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.projectImagesItem {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 50px var(--lightGray);
  background-color: transparent;
}

.projectVideoItem {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 50px var(--lightGray);
}

/* Safari seems to have a bug with object-fit (in small screens) so I had to give this class to project pages with single image displayed */

.projectImagesItemSafari {
  width: 100%;
  min-height: 100%;
  border-radius: 15px;
  box-shadow: 0px 0px 50px var(--lightGray);
  background-color: transparent;
}

.projectImagesSafari {
  object-fit: cover;
  min-height: 100%;
  border-radius: 15px;
  background-color: transparent;
}

@media only screen and (max-width: 900px) {
  .projectImagesContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .projectImages {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .projectVideos {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

  .projectImagesSafari {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
    border-radius: 15px;
  }

  .projectImagesItem {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 50px var(--lightGray);
  }
}

/* YSBR: Detonately Illustration id 21 */

.projectImagesItem21:first-child {
  grid-column-start: 2;
  grid-column-end: 5;
  box-shadow: none;
}

.projectImagesItem21:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem21:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-start: 3;
  box-shadow: none;
}

.projectImagesItem21:nth-child(4) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-start: 4;
}

.projectImagesItem21:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 5;
  grid-row-start: 6;
}

.projectImagesItem21:nth-child(6) {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-start: 4;
}

.projectImagesItem21:nth-child(7) {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 6;
  grid-row-start: 7;
}

.projectImagesItem21:nth-child(8) {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 6;
  grid-row-start: 7;
}

@media only screen and (max-width: 900px) {
  
  .projectImagesItem21:first-child {
    grid-column-start: 1;
    grid-column-end: 5;
    box-shadow: none;
  }
  
  .projectImagesItem21:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 9;
  }
  
  .projectImagesItem21:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-start: 3;
    box-shadow: none;
  }
  
  .projectImagesItem21:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-start: 4;
  }
  
  .projectImagesItem21:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-start: 6;
  }
  
  .projectImagesItem21:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-start: 4;
  }
  
  .projectImagesItem21:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 6;
    grid-row-start: 7;
  }
  
  .projectImagesItem21:nth-child(8) {
    grid-column-start: 6;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-start: 7;
  }

}

/* European Aerosols: Magazine Advert id 20 */

.projectImagesItem20:first-child {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  box-shadow: none;
}

.projectImagesItem20:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem20:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 4;
}

.projectVideoItem20 {
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
}

@media only screen and (max-width: 900px) {
  
  .projectImagesItem20:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 5;
    box-shadow: none;
  }
  
  .projectImagesItem20:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  
  .projectImagesItem20:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  
  .projectVideoItem20 {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }

}

/* YSBR: Alien Surfer Illustration id 19 */

.projectImagesItem19:first-child {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
}

.projectImagesItem19:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem19:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem19:nth-child(4) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
}

.projectImagesItem19:nth-child(5) {
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 5;
}

.projectImagesItem19:nth-child(6) {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
}

@media only screen and (max-width: 900px) {

  .projectImagesItem19:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 9;
    grid-row-end: 10;
  }
  
  .projectImagesItem19:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  
  .projectImagesItem19:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  .projectImagesItem19:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  
  .projectImagesItem19:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
  }
  
  .projectImagesItem19:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start:6;
    grid-row-end: 7;
  }
  
  .projectImagesItem19:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 8;
    grid-row-end: 9;
  }
  
  .projectImagesItem19:nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

}

/* YSBR: YesBro Flag id 18 */

.projectImagesItem18:first-child {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  box-shadow: none;
}

.projectImagesItem18:nth-child(2) {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem18:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 4;
  box-shadow: none;
}

.projectVideoItem18 {
  grid-column-start: 5;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
}

@media only screen and (max-width: 900px) {
  
  .projectImagesItem18:first-child {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    box-shadow: none;
  }
  
  .projectImagesItem18:nth-child(2) {
    grid-column-start: 4;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  
  .projectImagesItem18:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
    box-shadow: none;
  }

  .projectVideoItem18 {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

}

/* Product Display Rendering id 17 */

.projectImagesItem17:first-child {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 4;
  box-shadow: none;
}

.projectImagesItem17:nth-child(2) {
  grid-column-start: 6;
  grid-column-end: 7;
  grid-row-start: 3;
  grid-row-end: 4;
  box-shadow: none;
}

.projectImagesItem17:nth-child(3) {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 2;
  box-shadow: none;
}

.projectImagesItem17:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  box-shadow: none;
}

.projectImagesItem17:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.projectImagesItem17:nth-child(6) {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 6;
  grid-row-end: 7;
  box-shadow: none;
}

.projectImagesItem17:nth-child(7) {
  grid-column-start: 4;
  grid-column-end: 9;
  grid-row-start: 6;
  grid-row-end: 7;
}

.projectImagesItem17:nth-child(8) {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 5;
  grid-row-end: 6;
}

@media only screen and (max-width: 900px) {
  
  .projectImagesItem17:first-child {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 4;
    grid-row-end: 5;
    box-shadow: none;
  }
  
  .projectImagesItem17:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 4;
    grid-row-end: 5;
    box-shadow: none;
  }
  
  .projectImagesItem17:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 2;
    box-shadow: none;
  }
  
  .projectImagesItem17:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 2;
    grid-row-end: 3;
    box-shadow: none;
  }
  
  .projectImagesItem17:nth-child(5) {
    grid-column-start: 2;
    grid-column-end: 8;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .projectImagesItem17:nth-child(6) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 7;
    box-shadow: none;
  }
  
  .projectImagesItem17:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 7;
    grid-row-end: 8;
  }

  .projectImagesItem17:nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
  }

}

/* JAT: Job Applications Tracker id 16 */

.projectImagesItem16:first-child {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
  box-shadow: none;
}

.projectImagesItem16:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  box-shadow: none;
}

.projectImagesItem16:nth-child(3) {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem16:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 3;
}

.projectImagesItem16:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 4;
}

.projectImagesItem16:nth-child(6) {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 4;
}

@media only screen and (max-width: 900px) {
  
  .projectImagesItem16:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
    box-shadow: none;
  }
  
  .projectImagesItem16:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
    box-shadow: none;
  }
  
  .projectImagesItem16:nth-child(3) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  
  .projectImagesItem16:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 5;
  }
  
  .projectImagesItem16:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 6;
    grid-row-end: 8;
  }
  
  .projectImagesItem16:nth-child(6) {
    max-height: 50px;
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
  }

}

/* ABKC American Bully Kennel Club: Pitbull Mascots Illustrations id 15 */

.projectImagesItem15:first-child {
  grid-column-start: 2;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 1;
  box-shadow: none;
}

.projectImagesItem15:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 1;
  box-shadow: none;
}

.projectImagesItem15:nth-child(3) {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem15:nth-child(4) {
  grid-row-start: 2;
  grid-row-end: 5;
  grid-column-start: 6;
  grid-column-end: 8;
}

.projectImagesItem15:nth-child(5) {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 6;
}

.projectImagesItem15:nth-child(6) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 4;
  grid-column-end: 6;
}

.projectImagesItem15:nth-child(7) {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 4;
  grid-column-end: 6;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem15:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
    box-shadow: none;
  }

  .projectImagesItem15:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
    box-shadow: none;
  }

  .projectImagesItem15:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .projectImagesItem15:nth-child(4) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 4;
    grid-column-end: 9;
  }

  .projectImagesItem15:nth-child(5) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .projectImagesItem15:nth-child(6) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 5;
    grid-column-end: 9;
  }

  .projectImagesItem15:nth-child(7) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
/* Dupli-Color Il Ritocco Muri: Product Label id 14 */

.projectImagesItem14:first-child {
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem14:nth-child(2) {
  grid-column: span 4;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem14:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow: none;
  }
  .projectImagesItem14:nth-child(2) {
    grid-column: span 9;
  }
}

/* ToDoList App: Web Application id 13 */

.projectImagesItem13:first-child {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem13:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
  }
}

.projectImagesItemSafari13:first-child {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
}

@media only screen and (max-width: 900px) {
  .projectImagesItemSafari13:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
  }
}

/* Dupli-Color Il Copri Macchie: Product Label id 12 */

.projectImagesItem12:first-child {
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem12:nth-child(2) {
  grid-column: span 4;
}

.projectImagesItem12:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}

.projectImagesItem12:nth-child(4) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
}

.projectImagesItem12:nth-child(5) {
  grid-column-start: 4;
  grid-column-end: 8;
  grid-row-start: 2;
  grid-row-end: 4;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem12:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .projectImagesItem12:nth-child(2) {
    grid-column: span 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .projectImagesItem12:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  
  .projectImagesItem12:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
  }
  
  .projectImagesItem12:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 5;
  }
}

/* Breis Beats: Logo id 11 */

.projectImagesItem11:first-child {
  grid-column-start: 3;
  grid-column-end: 5;
  box-shadow: none;
}

.projectImagesItem11:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 7;
}

.projectImagesItem11:nth-child(3) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 8;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem11:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow: none;
  }

  .projectImagesItem11:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem11:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Green Magic: CBD Packaging Illustrations id 10 */

.projectImagesItem10:first-child {
  grid-column-start: 2;
  grid-column-end: 8;
}

.projectImagesItem10:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem10:nth-child(3) {
  grid-column: span 2;
}

.projectImagesItem10:nth-child(4) {
  grid-column: span 2;
}

.projectImagesItem10:nth-child(5) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem10:nth-child(6) {
  grid-column: span 2;
}

.projectImagesItem10:nth-child(7) {
  grid-column: span 2;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem10:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem10:nth-child(2) {
    grid-column: span 4;
  }

  .projectImagesItem10:nth-child(3) {
    grid-column: span 4;
  }

  .projectImagesItem10:nth-child(4) {
    grid-column: span 4;
  }

  .projectImagesItem10:nth-child(5) {
    grid-column: span 4;
  }

  .projectImagesItem10:nth-child(6) {
    grid-column: span 4;
  }

  .projectImagesItem10:nth-child(7) {
    grid-column: span 4;
  }
}

/* Paola and Filippo: Animated Wedding Invitation id 9 */

.projectImagesItem9:nth-child(1) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: white;

}

.projectImagesItem9:nth-child(2) {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: white;  
}

.projectImagesItem9:nth-child(3) {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 4;
  background-color: white;  
}

.projectImagesItem9:nth-child(4) {
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  background-color: white;  
}

.projectImagesItem9:nth-child(5) {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 5;
  background-color: white;
}
.projectImagesItem9:nth-child(6) {
  grid-column-start: 6;
  grid-column-end: 8;
  grid-row-start: 4;
  grid-row-end: 5;
}

.projectImagesItem9:nth-child(7) {
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
  opacity: 1;
}

.projectImagesItem9:nth-child(7):hover {
  opacity: 0.75;
}


@media only screen and (max-width: 900px) {

  .projectImagesItem9:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  
  }
  
  .projectImagesItem9:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
    
  }
  
  .projectImagesItem9:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
    
  }
  
  .projectImagesItem9:nth-child(4) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 3;
    grid-row-end: 4;
    
  }
  
  .projectImagesItem9:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .projectImagesItem9:nth-child(6) {
    grid-column-start: 5;
    grid-column-end: 9;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .projectImagesItem9:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 1;
    grid-row-end: 2;
  }

}

/* DRV: Logo id 8 */

.projectImagesItem8:first-child {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 4;
  grid-column-end: 8;
}

.projectImagesItem8:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 4;
  box-shadow: none;
}

.projectImagesItem8:nth-child(3) {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 5;
  box-shadow: none;
}

.projectImagesItem8:nth-child(4) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem8:nth-child(5) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 5;
}

.projectImagesItem8:nth-child(6) {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 4;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem8:first-child {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem8:nth-child(2) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow: none;
  }

  .projectImagesItem8:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 9;
    box-shadow: none;
  }

  .projectImagesItem8:nth-child(4) {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem8:nth-child(5) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem8:nth-child(6) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Dupli-Color: All Season Spray Leaflet id 7 */

.projectImagesItem7 {
  box-shadow: inset 0px 50px var(--gray);
}

.projectImagesItem7:first-child {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem7:nth-child(2) {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 2;
  grid-column-end: 5;
}

.projectImagesItem7:nth-child(3) {
  grid-row-start: 4;
  grid-row-end: 5;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem7:nth-child(4) {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 3;
  box-shadow: none;
}

.projectImagesItem7:nth-child(5) {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 5;
  box-shadow: 0px 0px 50px var(--lightGray);
}

@media only screen and (max-width: 900px) {
  .projectImagesItem7 {
    box-shadow: inset 0px 50px var(--gray);
  }

  .projectImagesItem7:first-child {
    grid-row-start: 6;
    grid-row-end: 7;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem7:nth-child(2) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem7:nth-child(3) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem7:nth-child(4) {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
    box-shadow: none;
  }

  .projectImagesItem7:nth-child(5) {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 9;
    box-shadow: 0px 0px 50px var(--lightGray);
  }
}

/* Dupli-Color: Pricing List Catalog id 6 */

.projectImagesItem6:first-child {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 8;
}

.projectImagesItem6:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem6:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 5;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem6:first-child {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem6:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .projectImagesItem6:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Dupli-Color: Products Catalog id 5 */

.projectImagesItem5:first-child {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-column-end: 8;
}

.projectImagesItem5:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem5:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 5;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem5:first-child {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem5:nth-child(2) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem5:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Settimo Tricot Club: Logo id 4 */

.projectImages4 {
  object-fit: fill;
}

.projectImagesItem4:first-child {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  z-index: 1;
  padding: 10px;
  background-color: white;
  box-shadow: none;
}

.projectImagesItem4:nth-child(2) {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem4:nth-child(3) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem4:nth-child(4) {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 5;
  box-shadow: none;
}

@media only screen and (max-width: 900px) {
  .projectImages4 {
    object-fit: fill;
  }
  
  .projectImagesItem4:first-child {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 9;
    z-index: 1;
    padding: 10px;
    background-color: white;
    box-shadow: none;
  }
  
  .projectImagesItem4:nth-child(2) {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem4:nth-child(3) {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem4:nth-child(4) {
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 9; 
    box-shadow: none;
  }
}

/* Urmet: Iuvs Plus App id 3 */

.projectImagesItemSafari3:first-child {
  grid-column-start: 2;
  grid-column-end: 8;
  background-color: transparent;
  box-shadow: none;
}

@media only screen and (max-width: 900px) {
  .projectImagesItemSafari3:first-child {
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Pixxa: Challenge Blackboard and Instructions Flyer id 2 */

.projectImagesItem2:first-child {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 6;
}

.projectImagesItem2:nth-child(2) {
  grid-row-start: 3;
  grid-column-start: 2;
  grid-column-end: 4;
}

.projectImagesItem2:nth-child(3) {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 6;
  grid-column-end: 8;
}

.projectImagesItem2:nth-child(4) {
  grid-row-start: 3;
  grid-column-start: 6;
  grid-column-end: 8;
}

.projectImagesItem2:nth-child(5) {
  grid-row-start: 3;
  grid-column-start: 4;
  grid-column-end: 6;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem2:first-child {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem2:nth-child(2) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem2:nth-child(3) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem2:nth-child(4) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem2:nth-child(5) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}

/* Pixxa: Journey Blackboard id 1 */

.projectImagesItem1:first-child {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 5;
}

.projectImagesItem1:nth-child(2) {
  grid-row-start: 2;
  grid-row-end: 3;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem1:nth-child(3) {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 5;
  grid-column-end: 8;
}

.projectImagesItem1:nth-child(4) {
  grid-row-start: 3;
  grid-row-end: 4;
  grid-column-start: 5;
  grid-column-end: 8;
}

@media only screen and (max-width: 900px) {
  .projectImagesItem1:first-child {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem1:nth-child(2) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem1:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 9;
  }
  
  .projectImagesItem1:nth-child(4) {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 9;
  }
}
