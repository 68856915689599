.SkillsContainer {
    display: flex;
}

.SkillsList {
    width: 100%;
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
}

.SkillsItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.SkillsItem h1{
  display: flex;
  color: var(--gray);
  font-weight: 700;
  font-size: 1rem;
  padding: 10px 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 5px 15px var(--lightGray);
}

/* .SkillsItem:nth-child(1) h1{
  background-color: var(--red);
  color: white;
}

.SkillsItem:nth-child(2) h1{
  background-color: var(--red);
  color: white;
}

.SkillsItem:nth-child(3) h1{
  background-color: var(--red);
  color: white;
}

.SkillsItem:nth-child(4) h1{
  background-color: var(--gray);
  color: white;
}

.SkillsItem:nth-child(5) h1{
  background-color: var(--gray);
  color: white;
} */

.skillsWrapper{
  background-color: var(--lightRed);
  padding: 10px;
  border-radius: 50%;
}

.skillsWrapper4{
  background-color: var(--lightGray);
}

.skillsWrapper5{
  background-color: var(--lightGray);
}

.circularSkills {
  position: relative;
  width: 150px;
  height: 150px;
  background: conic-gradient(#3c3c3c, 3.6deg, transparent, 360deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.circularSkills::before {
  content: "";
  position: absolute;
  height: 110px;
  width: 110px;
  background-color: #fff0f0;
  border-radius: 50%;
  box-shadow: 0px 1px 0 var(--lightGray);
}

.circularSkills4::before{
  content: "";
  position: absolute;
  height: 110px;
  width: 110px;
  background-color: #efefef;
  border-radius: 50%;
  box-shadow: 0px 1px 0 var(--lightGray);
}

.circularSkills5::before{
  content: "";
  position: absolute;
  height: 110px;
  width: 110px;
  background-color: #efefef;
  border-radius: 50%;
  box-shadow: 0px 1px 0 var(--lightGray);
}

.progressValue {
  position: relative;
  font-weight: 600;
  font-size: 2rem;
  color: var(--gray);
}

.progressValue p{
  font-weight: 300;
  font-size: 1rem;
}

.ballWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.ballOne {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--red);
  border-radius: 50%;
  top: 0;
}

.ballOne4 {
  background-color: var(--gray);
}

.ballOne5 {
  background-color: var(--gray);

}

.ballTwo {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--red);
  border-radius: 50%;
  top: 0;
}

.ballTwo4 {
  background-color: var(--gray);
}

.ballTwo5 {
  background-color: var(--gray);
}

@media only screen and (max-width: 900px) {

  .SkillsList {
    width: 100%;
    display: flex;
    list-style-type: none;
    justify-content: space-evenly;
}

}