.aboutMeIntro-Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 75px;
}

.aboutMeIntro-Title {
  padding: 50px 100px 0px 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aboutMeIntro-Title h1 {
  font-size: 3.5rem;
  text-shadow: 0.05em 0.05em var(--lightRed);
  color: var(--gray);
}

.aboutMeIntro-Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 75px;
}

.logoBG1 {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  opacity: 5%;
  z-index: -1;
}

.logoBG2 {
  position: absolute;
  height: 100%;
  right: 0;
  transform: translateX(50%);
  opacity: 5%;
  z-index: -1;
}

.aboutMeIntro-Box1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.aboutMeIntroAnimation {
  width: 250px;
  border-radius: 25px;
}

.aboutMeIntro-Box2 {
  width: 100%;
  display: flex;
  align-items: center;
}

.aboutMeIntro-Box2 p {
  font-size: 1.5rem;
  color: var(--gray);
}

.aboutMeIntro-Box2-List {
  display: flex;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.aboutMeIntro-Box2-ListItem {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
  flex-wrap: nowrap;
}

.aboutMeIntro-Box2-ListItemIcon {
  font-size: 2rem;
}

.aboutMeIntro-Box2-ListItemText1 {
  font-weight: 300;
  padding: 10px;
  color: var(--gray);
  border-radius: 10px;
  background-color: var(--lightRed);
  font-weight: 500;
}

.aboutMeIntro-Box2-ListItemText1:active {
  color: var(--gray);
}

.aboutMeIntro-Box2-ListItemText2 {
  font-weight: 300;
  padding: 10px;
  color: white;
  border-radius: 10px;
  background-color: var(--red);
  font-weight: 500;
}

.aboutMeIntro-Box2-ListItemText2:active {
  color: white;
}

.aboutMeIntro-Box2-ListItemText3 {
  font-weight: 300;
  padding: 10px;
  color: white;
  border-radius: 10px;
  background-color: var(--gray);
  font-weight: 500;
}

.aboutMeIntro-Box2-ListItemText3:active {
  color: white;
}

.aboutMeIntro-Box2-ListItemText:active {
  color: var(--gray);
}

.downContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 10px;
  gap: 15px;
}

.downContainer h1 {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--gray);
}

/* AboutMeIntro [[[[[[[[[[Responsive]]]]]]]]]] */

@media only screen and (max-width: 768px) {
  .aboutMeIntro-Container {
    padding: 0px 25px 50px;
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
    grid-template-rows: none;
    gap: 0px;
  }

  .aboutMeIntro-Wrapper {
    gap: 0px;
  }

  .aboutMeIntro-Box1 {
    align-items: center;
    justify-content: center;
  }

  .aboutMeIntro-Title {
    padding: 25px;
  }

  .aboutMeIntro-Title h1 {
    font-size: 2rem;
    text-shadow: 0.05em 0.05em var(--lightRed);
    color: var(--gray);
    text-align: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 15px 15px var(--lightGray);
    border-radius: 10px;
  }

  .downContainer {
    display: none;
  }

  .aboutMeIntro-Box2 {
    padding: 50px 25px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .aboutMeIntro-Box2-List {
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 50px;
  }

  .aboutMeIntro-Box2-ListItem {
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }

  .aboutMeIntro-Box2-ListItemText {
    border: none;
    color: var(--gray);
    background-image: none;
    border-radius: none;
    box-shadow: none;
  }

  .logoBG1 {
    display: none;
  }
  
  .logoBG2 {
    display: none;
  }

  .cloudPointer {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    
    border-top: 20px solid white;
  }

  .aboutMeIntroAnimation {
    width: 250px;
    border-radius: 25px;
  }
}
